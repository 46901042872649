:root {

  --body-color: #F8F5F2;

  /* 1st color */

  /* --primary-color: #D19F68;
  --primary-color-rgb: rgba(209, 159, 104, 0.7);
  --secondary-color: #002f4b80;
  --secondary-color-rgb: rgba(0,47,75,0.5); */
  

  /* 2nd colors */

  /* --primary-color: #92A4B1;
  --primary-color-rgb: rgba(145, 163, 176, 0.7);
  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7);
   */

  /* 3rd colors */

  /* --primary-color: #936F62;
  --primary-color-rgb: rgba(148, 111, 97, 0.7);
  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7); */
  

  /* 4rd colors */

  /* --primary-color: #AD8786;
  --primary-color-rgb: rgba(172, 135, 134, 0.7);
  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7); */
  

  /* 5rd colors */

  --primary-color: #AD8786;
  --primary-color-rgb: rgba(172, 135, 134, 0.7);
  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7);



}