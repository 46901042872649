/* ==========================================================================
  05. HERO
  ========================================================================== */

.hero-section,
.hero-section .container {
  position: relative;
  z-index: 3;
}

/*------------------------------------------*/
/*  HERO-3
/*------------------------------------------*/

#hero-3 {
  margin-top: 75px;
  padding-top: 120px;
}

#hero-3:after {
  background-color: #f2f2f0;
  position: absolute;
  width: 100%;
  height: 86%;
  top: 0;
  content: "";
  z-index: -1;
}

.theme--dark #hero-3:after {
  background-color: #1c1d1e;
}

.hero-3-txt {
  text-align: center;
  margin-bottom: 70px;
  padding: 0 14%;
}

.hero-3-txt h2 {
  font-size: 3.85rem;
  margin-bottom: 30px;
}

.hero-3-txt {
  text-align: center;
  margin-bottom: 70px;
  padding: 0 14%;
}

.hero-3-txt h2 {
  font-size: 3.85rem;
  margin-bottom: 30px;
}
